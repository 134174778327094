import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const ProductDetails = () => {
    // Get the product ID from the URL params
    const { id } = useParams();

    // State to toggle the video display
    const [showDemo, setShowDemo] = useState(false);

    // Define product details (this could also come from an API or database)
    const productDetails = {
        1: {
            title: "SpeakUp",
            description: "Take your customer service to the next level while controlling operating costs. Resolve issues quickly and delight customers with AI-powered self-service. Set your agents up for success with real-time information to boost their productivity.",
            image: "https://img.freepik.com/free-vector/woman-speaking-megaphone-feminist-support-vector_53876-61421.jpg?t=st=1727157657~exp=1727161257~hmac=4e3479b04ddb74d610ab16b36679c75133ee89aad7a323654aaa7090d9de1083&w=740", // Replace with actual image URL
            demoVideo: "https://www.youtube.com/embed/tgbNymZ7vqY", // Replace with actual video URL
            contactLink: "/contact-us"
        },
        2: {
            title: "Survey",
            description: "Take your customer service to the next level while controlling operating costs. Resolve issues quickly and delight customers with AI-powered self-service. Set your agents up for success with real-time information to boost their productivity.",
            image: "https://img.freepik.com/free-vector/feedback-survey-concept-illustration_114360-17632.jpg?t=st=1727157665~exp=1727161265~hmac=f8ce01f59b329099af960f283d46b7e79cbbe4bdafa89d69127e1831db79c0e9&w=740", // Replace with actual image URL
            demoVideo: "https://www.youtube.com/embed/tgbNymZ7vqY", // Replace with actual video URL
            contactLink: "/contact-us"
        },
        3: {
            title: "Order Tracking",
            description: "Take your customer service to the next level while controlling operating costs. Resolve issues quickly and delight customers with AI-powered self-service. Set your agents up for success with real-time information to boost their productivity.",
            image: "https://img.freepik.com/free-vector/post-service-tracking-abstract-concept-vector-illustration-parcel-monitor-track-trace-your-shipment-package-tracking-number-express-delivery-online-shopping-mail-box-abstract-metaphor_335657-1777.jpg?t=st=1727157992~exp=1727161592~hmac=e8a38b61fe55fcd86dca46c34c877758aafdd5ab6744a3a92768a93a1dde8373&w=740", // Replace with actual image URL
            demoVideo: "https://www.youtube.com/embed/tgbNymZ7vqY", // Replace with actual video URL
            contactLink: "/contact-us"
        },
        // Define other product details here...
    };

    const product = productDetails[id];

    return (
        <div className="flex flex-col md:flex-row justify-center items-center p-6 gap-8">
            {/* Left Column: Product Details */}
            <div className="w-full md:w-1/2">
                <h2 className="text-4xl font-bold mb-4">{product.title}</h2>
                <p className="text-lg text-gray-700 mb-6">{product.description}</p>
                <div className="flex space-x-4">
                    <a href={product.contactLink} className="bg-green-500 text-white px-4 py-2 rounded-lg">
                        Contact
                    </a>
                    {/* Button to show the demo video */}
                    <button
                        onClick={() => setShowDemo(true)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                    >
                        Demo
                    </button>
                </div>
            </div>

            {/* Right Column: Product Image or Interactive Section */}
            <div className="w-full md:w-1/2 flex justify-center">
                {!showDemo ? (
                    <img src={product.image} alt={product.title} className="rounded-lg shadow-lg w-full" />
                ) : (
                    <div className="w-full mt-8"> {/* Added margin-top here */}
                        {/* Embedded YouTube video */}
                        <iframe
                            width="100%"
                            height="315"
                            src={product.demoVideo}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductDetails;
