// components/Layout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/NavBar';
import Footer from '../components/Footer';

const Layout = () => {
  return (
    <>
      <Navbar />
      <main>
        <Outlet /> {/* This is where your route content will be displayed */}
      </main>
      <Footer />
    </>
  );
}

export default Layout;
