import React from 'react';
import ProductCard from './Productcard'; // Import the reusable ProductCard component
import audit from '../images/Audit-rafiki (1).png'

const MoreProducts = () => {
    // Define the product data in an array
    const products = [
        {
            id: 1,
            title: "SpeakUp",
            description: "This is the description for product 1.",
            image: "https://img.freepik.com/free-vector/woman-speaking-megaphone-feminist-support-vector_53876-61421.jpg?t=st=1727157657~exp=1727161257~hmac=4e3479b04ddb74d610ab16b36679c75133ee89aad7a323654aaa7090d9de1083&w=740", // Replace with actual image link
            link: "/product/1"
        },
        {
            id: 2,
            title: "Survey",
            description: "This is the description for product 2.",
            image: "https://img.freepik.com/free-vector/feedback-survey-concept-illustration_114360-17632.jpg?t=st=1727157665~exp=1727161265~hmac=f8ce01f59b329099af960f283d46b7e79cbbe4bdafa89d69127e1831db79c0e9&w=740", // Replace with actual image link
            link: "/product/2"
        },
        {
            id: 3,
            title: "Order Tracking",
            description: "This is the description for product 3.",
            image: "https://img.freepik.com/free-vector/post-service-tracking-abstract-concept-vector-illustration-parcel-monitor-track-trace-your-shipment-package-tracking-number-express-delivery-online-shopping-mail-box-abstract-metaphor_335657-1777.jpg?t=st=1727157992~exp=1727161592~hmac=e8a38b61fe55fcd86dca46c34c877758aafdd5ab6744a3a92768a93a1dde8373&w=740", // Replace with actual image link
            link: "/product/3"
        },
        {
            id: 3,
            title: "Travel Management",
            description: "This is the description for product 3.",
            image: "https://img.freepik.com/free-vector/booking-flight-tickets-online-flat-vector-concept_81522-1139.jpg?t=st=1727157791~exp=1727161391~hmac=deb55779cd7a7bd3025859b0b4b7f0dc6835900a614c04b3a92b3a1ac4456fb7&w=740", // Replace with actual image link
            link: "/product/4"
        },
        {
            id: 3,
            title: "Safety",
            description: "This is the description for product 3.",
            image: "https://img.freepik.com/free-vector/flat-background-safer-internet-day_23-2151127500.jpg?t=st=1727158147~exp=1727161747~hmac=d361a35bef603194be737ae256f6a726230b42521fa14602f1af87b5a4de1e67&w=740", // Replace with actual image link
            link: "/product/5"
        },
        {
            id: 3,
            title: "Customer Complaint",
            description: "This is the description for product 3.",
            image: "https://img.freepik.com/free-vector/flat-call-center-concept-background_23-2148185272.jpg?t=st=1727158549~exp=1727162149~hmac=c0f83e8afb53acf58820757097d18690bb72a8491868f5a94836809cca21ab42&w=740", // Replace with actual image link
            link: "/product/6"
        },
        {
            id: 3,
            title: "Litigation Management",
            description: "This is the description for product 3.",
            image: "https://img.freepik.com/free-vector/prosecutor-concept-illustration_114360-13319.jpg?t=st=1727159336~exp=1727162936~hmac=528e72d944378a8ac6d15fc5358ef31578c9ca2a2399f808ce2443a2f5537785&w=740", // Replace with actual image link
            link: "/product-3"
        },{
            id: 3,
            title: "Audit Tracker",
            description: "This is the description for product 3.",
            image: audit, // Replace with actual image link
            link: "/product-3"
        },{
            id: 3,
            title: "Skill Matrix",
            description: "This is the description for product 3.",
            image: "https://img.freepik.com/free-vector/iso-certification-illustration_23-2148688046.jpg?t=st=1727159472~exp=1727163072~hmac=0d96a9ceff799f1f6c0f474a21a63c5e49f020525c510fbd9834802cc409d92d&w=740", // Replace with actual image link
            link: "/product-3"
        },
        // Add more products here...
    ];

    return (
        <div className="my-4 py-4 pt-12" id="more-products"> {/* Adjusted padding-top */}
        {/* Removed the More Products text as per your request */}
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">More Products</h2>
        <div className='flex justify-center'>
                <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
            </div>
    
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 px-2 mt-6">
            {products.map(product => (
                <div className="max-w-xs p-4 shadow-lg rounded-lg overflow-hidden bg-white mx-auto" key={product.id}>
                    {/* Prevent Image Cropping */}
                    <img src={product.image} alt={product.title} className="w-full h-40 object-contain" />
                    <div className="p-2">
                        <h3 className="text-lg font-semibold">{product.title}</h3>
                        <p className="text-sm text-gray-600">{product.description}</p>
                        <a href={product.link} className="mt-2 inline-block text-blue-500 text-sm">Learn More</a>
                    </div>
                </div>
            ))}
        </div>
    </div>
);
};

export default MoreProducts;
