import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import MoreProducts from './components/moreproducts';
import Layout from './components/layout'; 
import ProductDetails from './components/ProductDetails';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: 'ease-out-cubic',
    });
  
    // This will refresh AOS when the route changes
    AOS.refresh();
  }, []);

  useDocTitle("Wizro - Let AI Drive Your Work");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/more-products" element={<MoreProducts />} />
            <Route path="/product/:id" element={<ProductDetails/>}/>
            </Route>
          </Routes>
          
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
